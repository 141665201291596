import React from "react";
import styled from "styled-components";
import { useWindowSize } from "../hooks/useWindowSize";
import logoPath from "../images/logo.svg";

const LogoImageContainer = styled.div`
	position: relative;
	top: -40%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SubtitleSpan = styled.span`
	color: white;
	font-size: 24px;
`;

const SmallSubtitleSpan = styled.span`
	color: white;
	font-size: 24px;

	@media(max-width: ${props => props.theme.breakpoint}) {
		font-size: 16px;
	}
`;

const LogoImage = styled.img`
	margin-bottom: 0;
	max-width: 90%;
`;

const HeaderContainer = styled.header`
	width: 100%;
	height: 100vh;
	background: black;
	padding: 0;
	margin: 0;
	text-align: center;
`;

const SmallHeaderContainer = styled.header`
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	background: black;
	align-items: center;
	border-bottom: 1px solid ${props => props.theme.accentColour};
`;

export interface IHeaderProps {
	fullHeader?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ fullHeader }) => {
	const { width, height, devicePixelRatio } = useWindowSize(Number.MAX_SAFE_INTEGER, 0);

	const dpr = devicePixelRatio.toFixed(1);
	const headerUrl = `https://res.cloudinary.com/flynn-effect/image/upload/c_fill,w_${width},h_${height}/dpr_${dpr}/v1585520849/Website/header.jpg`;

	if (fullHeader) {
		return (
			<HeaderContainer>
				<img
					src={headerUrl}
					style={{ height: "100%", objectFit: "cover" }}
				/>
				<LogoImageContainer>
					<LogoImage src={logoPath} /> <br />
					<SubtitleSpan>A Melbourne-based alternative rock band</SubtitleSpan>
				</LogoImageContainer>
			</HeaderContainer>
		);
	} else {
		return (
			<SmallHeaderContainer>
				<LogoImage src={logoPath} /> <br />
				<SmallSubtitleSpan>A Melbourne-based alternative rock band</SmallSubtitleSpan>
			</SmallHeaderContainer>
		);
	}
};

export default Header;
