import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

export interface INavItemProps extends INavbarEntry {
	active: boolean;
}

export interface INavbarEntry {
	link: string;
	caption: string;
}

interface INavLinkProps {
	isActivePage: boolean;
}

const NavLI = styled.li`
	list-style-type: none;

	@media (max-width: 575px) {
		text-align: right;
		padding-right: 20px;
	}
`;

const NavSpan = styled.span<INavLinkProps>`
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 13px;
	letter-spacing: 1.1px;
	transition-property: color;
	transition-duration: 0.25s;
	color: ${props => props.isActivePage ? "hsl(210, 70%, 38%)" : "rgba(255, 255, 255, 0.8)"};

	:hover {
		color: rgba(255, 255, 255, 1);
	}

	@media(min-width: 576px) {
		padding: 20px;
	}
`;

export const NavItem: React.FC<INavItemProps> = ({ link, caption, active }) => {
	return (
		<NavLI>
			<Link to={link}><NavSpan isActivePage={active}>{caption}</NavSpan></Link>
		</NavLI>
	);
};