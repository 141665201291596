import { useWindowSize } from "../hooks/useWindowSize";

export type CloudinaryAssetType = "image";
export type CloudinarySource = "upload";
export type CloudinaryCropType = "scale" | "fit" | "limit" | "mfit" | "pad" | "lpad" | "mpad" | "crop" | "fill" | "lfill" | "fill_pad" | "thumb";
export type CloudinaryDPR = "auto" | number;

export interface CloudinaryTransformations {
	height?: string | number;
	width?: string | number;
	cropType?: CloudinaryCropType;
	devicePixelRatio?: CloudinaryDPR;
}

const optionMap = new Map<keyof CloudinaryTransformations, string>([
	["height", "h"],
	["width", "w"],
	["cropType", "c"],
	["devicePixelRatio", "dpr"]
]);

export class CloudinaryImage {
	public secure: boolean;
	public cloud: string;
	public type: CloudinaryAssetType;
	public source: CloudinarySource;
	public id: string;
	public publicPath: string;
	public extension: string;
	public transformations: CloudinaryTransformations;

	constructor() {
		this.secure = false;
		this.cloud = "";
		this.type = "image";
		this.source = "upload";
		this.id = "";
		this.publicPath = "";
		this.extension = "";
		this.transformations = {};
	}

	public getUrl(): string {
		const parts: string[] = [];
		parts.push(this.secure ? "https://res.cloudinary.com" : "http://res.cloudinary.com");
		parts.push(this.cloud);
		parts.push(this.type);
		parts.push(this.source);
		const transformationParts: string[] = [];
		for (const transformation of Object.keys(this.transformations)) {
			const transformationKey = optionMap.get(transformation as keyof CloudinaryTransformations);
			const transformationValue = this.transformations[transformation as keyof CloudinaryTransformations];
			transformationParts.push(`${transformationKey}_${transformationValue}`);
		}
		parts.push(transformationParts.join(","));
		parts.push(this.id);
		parts.push(`${this.publicPath}.${this.extension}`);

		const url = parts.join("/");
		return url;
	}

	public static autoUrl(url: string, maxWidth?: number, maxHeight?: number): string {
		const image = CloudinaryImage.parse(url);
		const { width, devicePixelRatio } = useWindowSize(maxWidth || 960, 5);

		const trueMaxWidth = (maxWidth && maxWidth < width) ? maxWidth : width;
		image.transformations.width = trueMaxWidth;
		if (maxHeight) {
			image.transformations.height = maxHeight;
		}
		image.transformations.devicePixelRatio = devicePixelRatio;
		image.transformations.cropType = maxHeight ? "thumb" : "limit";
		return image.getUrl();
	}

	public static parse(url: string): CloudinaryImage {
		const image = new CloudinaryImage();

		const parts = url.split("/");
		image.secure = parts[0] === "https:";
		image.cloud = parts[3];
		image.type = parts[4] as CloudinaryAssetType;
		image.source = parts[5] as CloudinarySource;

		const transformations = parts[6].split(",");
		for (const transformation of transformations) {
			const transformationParts = transformation.split("_");
			const urlKey = transformationParts[0];
			const urlValue = transformationParts[1];
			const mapEntry = Array.from(optionMap.entries()).find((entry) => entry[1] === urlKey);
			if (mapEntry) {
				const mapKey = mapEntry[0] as keyof CloudinaryTransformations;
				image.transformations[mapKey] = urlValue as any;
			}
		}

		image.id = parts[7];
		const publicPathParts = parts.slice(8, parts.length).join("/");
		const imageDotParts = publicPathParts.split(".");
		image.extension = imageDotParts[imageDotParts.length - 1];
		image.publicPath = imageDotParts.slice(0, imageDotParts.length - 1).join(".");

		return image;
	}
}

//https://res.cloudinary.com/flynn-effect/image/upload/c_scale,w_auto:100,dpr_auto/v1584682018/Website/Promo%20Posters/poster-progfest_vmcpos.jpg