import React, { useState } from "react";
import styled from "styled-components";
import { INavbarEntry, NavItem } from "./NavItem";
import { Menu } from "@styled-icons/boxicons-regular";

const Nav = styled.nav`
	margin: 1em 0 1em 0;

	@media(max-width: 575px) {
		position: sticky;
		top: 0;
	}
`;

const NavUL = styled.ul<INavbarContainerProps>`
	display: flex;
	justify-content: center;

	@media(max-width: 575px) {
		display: ${props => props.isExpanded ? "flex" : "none"};
		flex-direction: column;
	}

`;

interface INavbarContainerProps {
	isExpanded: boolean;
}

const HamburgerContainerDiv = styled.div`
	text-align: right;
	display: none;
	justify-content: end;

	@media(max-width: 575px) {
		display: flex;
	}
`;

const NavHamburger = styled.button`
	cursor: pointer;
	background: none;
	color: white;
	border: none;
	width: 64px;
`;

export interface INavbarProps {
	activePage: string;
}

export const Navbar: React.FC<INavbarProps> = ({ activePage }) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const items: INavbarEntry[] = [
		{
			caption: "News",
			link: "/#content"
		},
		{
			caption: "Live",
			link: "/live"
		},
		{
			caption: "Music",
			link: "/music"
		},
		{
			caption: "Photos",
			link: "/photos"
		},
		{
			caption: "Videos",
			link: "/videos"
		},
		{
			caption: "Bio",
			link: "/bio"
		},
		// {
		// 	caption: "Merchandise",
		// 	link: "/merchandise"
		// },
		{
			caption: "Contact",
			link: "/contact"
		}
	];

	return (
		<Nav>
			<HamburgerContainerDiv>
				<NavHamburger onClick={() => setIsExpanded(!isExpanded)}>
					<Menu />
				</NavHamburger>
			</HamburgerContainerDiv>
			<NavUL isExpanded={isExpanded}>
				{items.map((item) => <NavItem {...item} key={item.caption} active={item.caption === activePage} />)}
			</NavUL>
		</Nav>
	);
};