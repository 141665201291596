import { useLayoutEffect, useState } from "react";

export interface IWindowSize {
	height: number;
	width: number;
	devicePixelRatio: number;

	containerWidth: number;
}

export const useWindowSize = (maxContainerWidth: number, marginPercent: number) => {
	const [size, setSize] = useState<IWindowSize>({
		width: 0,
		height: 0,
		devicePixelRatio: 1,
		containerWidth: 0,
	});

	useLayoutEffect(() => {
		function updateSize() {
			setSize({
				width: window.innerWidth,
				height: window.innerHeight,
				devicePixelRatio: window.devicePixelRatio,

				containerWidth: Math.min(maxContainerWidth, Math.round(window.innerWidth * ((100 - marginPercent * 2) / 100)))
			});
		}
		window.addEventListener("resize", updateSize);
		const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
		matchMedia(mqString).addListener(updateSize);
		updateSize();
		return () => {
			window.removeEventListener("resize", updateSize);
			matchMedia(mqString).removeListener(updateSize);
		};
	}, []);

	return size;
};