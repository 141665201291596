/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Typography from "typography";
import Header from "./Header";
import "./layout.css";
import { Navbar } from "./Navbar/Navbar";
import SEO from "./SEO";
import styled, { ThemeProvider } from "styled-components";
import { setAppElement } from "react-modal";

const typography = new Typography({
	headerFontFamily: ["Questrial"],
	bodyFontFamily: ["Questrial"],
});

typography.injectStyles();

setAppElement("#___gatsby");

export const maxWidth = 960;
export const breakpoint = 576;
export const marginPercent = 5;

export interface ILayoutProps {
	title: string;
	fullHeader?: boolean;
}

export interface ITheme {
	breakpoint: string;
	accentColour: string;
}

const theme: ITheme = {
	breakpoint: "576px",
	accentColour: "hsl(210, 70%, 38%)",
};

export const PageTitle = styled.h1`
	font-weight: 100;
	font-family: "Roboto Thin";
`;

export const MainContainerDiv = styled.div`
	margin: 0 ${marginPercent}% 0 ${marginPercent}%;
	max-width: ${maxWidth}px;
	padding: 0 0 1.45rem 0;

	@media (min-width: ${maxWidth}px) {
		margin: 0 auto;
	}
`;

const Layout: React.FC<ILayoutProps> = ({ children, title, fullHeader }) => {
	return (
		<>
			<ThemeProvider theme={theme}>
				<Header fullHeader={fullHeader} />
				<Navbar activePage={title} />
				<SEO title={title} />
				<main>
					<a id="content" />
					{children}
				</main>

			</ThemeProvider>
		</>
	);
};

export default Layout;
